import { template as template_2ce775e2051f497fad35beaf5559f5c9 } from "@ember/template-compiler";
const FKControlMenuContainer = template_2ce775e2051f497fad35beaf5559f5c9(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
