import { template as template_e6b6a77c99b243d5b860fe8f2bf95656 } from "@ember/template-compiler";
const FKText = template_e6b6a77c99b243d5b860fe8f2bf95656(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
